import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { InfinitySpin } from 'react-loader-spinner';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const nameRef = useRef(null);

  const sendEmail = (e) => {
    const isSubmitEnabled =
      phoneRef?.current?.value &&
      nameRef?.current?.value &&
      emailRef?.current?.value;
    if (!isSubmitEnabled) return;
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        '#contact-form'
      )
      .then((result) => {
        console.log(result);
        setStateMessage('Message sent. I will get back to you soon!');
        setIsSubmitting(false);
        setTimeout(() => {
          setStateMessage(null);
        }, 10000);
      })
      .catch((e) => {
        console.log(e);
        setStateMessage('Something went wrong, please try again later');
        setIsSubmitting(false);
        setTimeout(() => {
          setStateMessage(null);
        }, 10000);
      });
  };

  return (
    <div className='contact-form'>
      <div className='contact-form__text'>
        <div className='title'>Let’s Book You In</div>
        <div className='subtitle'>
          Get in touch and I will get back to you as soon as possible!
        </div>
        <div>
          I am available to come to your home in Tel Aviv-Jaffa:
          <div style={{ fontWeight: 'bold' }}>
            Thursday - Saturday, 10am - 8pm
          </div>
        </div>
        <div>
          If you are unable to host me at your home, let me know and we can
          arrange a suitable alternative. Please note, massages take 90 minutes
          with 10 minutes prior for set-up and consultation.
        </div>
      </div>
      <div className='contact-form__form'>
        {isSubmitting ? (
          <div>
            <InfinitySpin color='#f7be25' />
          </div>
        ) : stateMessage ? (
          <div>{stateMessage}</div>
        ) : (
          <form id='contact-form'>
            <label>Phone</label>
            <input
              ref={phoneRef}
              placeholder='required'
              required
              name='phone'
            />
            <label>Name</label>
            <input
              ref={nameRef}
              placeholder='required'
              required
              type='text'
              name='name'
            />
            <label>Email</label>
            <input
              ref={emailRef}
              placeholder='required'
              required
              type='email'
              name='email'
            />
            <label>Message</label>
            <textarea
              placeholder='Have a question, want more information, or ready to book in? Provide more information and I will get back to you as soon as possible. '
              name='message'
              className='contact-form__input__message'
            ></textarea>
            <input
              type='submit'
              onClick={sendEmail}
              className='button submit'
            ></input>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
